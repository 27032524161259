import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';


function LoginForm({ client, ...props }) {
	const navigate = useNavigate();
	const [error, setError] = useState({ email: "", password: "" })
	const [loginForm, setLoginForm] = useState({ email: '', password: '' })

	const toAddUserPage = () => {
		navigate("/register/")
	}

	const toResetPasswordPage = () => {
		navigate("/send-reset-password-email/")
	}

	const onLogin = (e) => {
		e.preventDefault();
		setError(false);
	
		if (loginForm.email === "") {
			setError({ email: "Email cannot be blank!" });
			return;
		}
		if (loginForm.password === "") {
			setError({ password: "Password cannot be blank!" });
			return;
		}
	
		client.login(loginForm.email, loginForm.password)
			.then(() => {
				if (props.stayOnCurrentPage) {
					return;
				}
				navigate("/dea");
			})
			.catch((err) => {
				console.log(err);
				if (err.response && err.response.status === 403 && err.response.data.message === "Password reset required") {
					navigate("/send-reset-password-email/", { state: { message: "Please reset your password." } });
				} else {
					setError({ email: err.response.data.message, password: err.response.data.message });
				}
			});
	};

	return (
		<Row className='justify-content-center align-items-center'>
			<Col lg={4} md={6} xs={10} className='px-0'>
				<Form onSubmit={(e) => onLogin(e)}>
					<FloatingLabel
						controlId="floatingInput"
						label="Email address"
						className="my-3">
						<Form.Control
							type="email"
							placeholder="n.surname@pathfinder23.com"
							error={error.email}
							isInvalid={error.email}
							value={loginForm.email}
							onChange={(e) => setLoginForm({ ...loginForm, email: e.target.value })}
						/>
						<Form.Control.Feedback type="invalid">
							{error.email}
						</Form.Control.Feedback>
					</FloatingLabel>

					<FloatingLabel
						controlId="floatingPassword"
						label="Password"
						className='mb-3'>
						<Form.Control
							type="password"
							placeholder="Password"
							error={error.password}
							isInvalid={error.password}
							value={loginForm.password}
							onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
						/>
						<Form.Control.Feedback type="invalid">
							{error.password}
						</Form.Control.Feedback>
					</FloatingLabel>

					<Row className='g-0'>
						<Button type="submit" variant='pf23' className='mx-auto'>
							Log in
						</Button>
						<div className='fs-5 mt-4 mb-3 text-start'>Create an account <a role="button" onClick={toAddUserPage} className="fw-bold hyperlink px-0">here</a>.</div>
						<div className='fs-5 mb-2 text-start'>Reset Your Password <a role="button" onClick={toResetPasswordPage} className="fw-bold hyperlink px-0">here</a>.</div>
					</Row>

				</Form>
			</Col>
		</Row>
	);
}

export default LoginForm;